body {
  margin: 0;
  font-family: 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
}

@media print {
  body {
    background-color: #fff !important;
  }
}

div {
  box-sizing: border-box;
}

code {
  font-family: 'Mulish', sans-serif !important;
}

iframe {
  border: none;
}

.Toastify__toast--warning {
  margin-bottom: 0 !important;
  min-height: 20px !important;
  box-shadow: none !important;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
  display: flex !important;
  color: #4e3c1d !important;
  background: #ffeed6 !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  border-radius: 4px !important;
  padding: 6px 16px !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: max-content !important;
  gap: 10px !important;
  div.Toastify__toast-body {
    padding: 8px 0 !important;
    min-width: 0 !important;
    overflow: auto !important;
  }
  button {
    display: flex !important;
    margin: auto !important;
  }
  svg {
    color: #4e3c1d !important;
  }
}

#rpv-core__popover-body-inner-search {
  max-height: inherit !important;
}

.Toastify__toast--success {
  margin-bottom: 0 !important;
  min-height: 20px !important;
  box-shadow: none !important;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
  display: flex !important;
  color: rgb(30, 70, 32) !important;
  background-color: rgb(237, 247, 237) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  border-radius: 4px !important;
  padding: 6px 16px !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: max-content !important;
  gap: 10px !important;
  div.Toastify__toast-body {
    padding: 8px 0 !important;
    min-width: 0 !important;
    overflow: auto !important;
  }
  button {
    display: flex !important;
    margin: auto !important;
  }
  svg {
    color: rgb(30, 70, 32) !important;
  }
}
